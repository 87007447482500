@import '~bootstrap/dist/css/bootstrap.min.css';

body {
	
}

.th1 {
	font-family: eurostile-extended, sans-serif;
	font-weight: 900;
	font-style: italic;
	font-size: 1.6rem;
	max-width: 600px;
	text-align: center;
	margin: 0 auto;
	color: white;
	position: absolute;
	top: 20px;
	right: 0;
	left: 0;
	z-index: 99999;
}

#home header {
	position: relative;
	height: 90vh;
	background: black;
	overflow: hidden;
	width: 100%;
}

.modalidades {
	position: relative;
	height: 90vh;
	width: 200%;
}

.modalidades .cont {
	min-width: 100vw;
	height: 90vh;
}

.modalidad {
	float: left;
	width: 25vw;
	height: 90vh;
	position: relative;
	overflow: hidden;
}

.mod {
	height: 70vh;
}

.modalidad .title, .mod .title {
	font-family: eurostile-extended, sans-serif;
	font-weight: 900;
	font-style: italic;
	font-size: 2rem;
	letter-spacing: -3px;
	color: white;
	position: absolute;
	bottom: 50px;
	text-transform: uppercase;
	left: 0;
	right: 0;
	text-align: center;
}

.mod .title {
	transition: all 200ms ease;
}

.mod:hover .title {
	transform: translateY(-10px);
}

.modalidad {
	cursor: pointer;
	transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modalidad:hover {
	width: 35vw;
}

.modalidad.reduce {
	width: calc(65vw / 3);
}

.modalidad.reduce-0 {
	width: 0;
}

.modalidad.active {
	width: 100vw;
}

.modalidad img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.mod img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modalidad.trial img, .mod.trial img {
	max-width: none;
	margin-left: -100px;
}

.modalidad.trial, #choose .trial {
	overflow: hidden;
	background: #ed9a04 url('../img/header-trial-bg.jpg') center center;
}

.modalidad.dh, #choose .dh {
	overflow: hidden;
	background: #059b1f url('../img/header-dh-bg.jpg') center center;
}

.modalidad.enduro, #choose .enduro {
	overflow: hidden;
	background: #1053ca url('../img/header-enduro-bg.jpg') center center;
}

.modalidad.mx, #choose .mx {
	overflow: hidden;
	background: #e73526 url('../img/header-mx-bg.jpg') center center;
}

.navbar {
	background: black;
}

.nav-item .nav-link {
	color: white;
}

.scroll {
	cursor: pointer;
	background: white;
	width: 70px;
	height: 70px;
	position: absolute;
	left: 50%;
	top: 92vh;
	border-radius: 50%;
	transform: translateX(-50%);
	z-index: 999999;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
}

.sc {
	display: flex;
	justify-content: center;
	align-items: center;
}

.chevron {
  position: absolute;
  width: 1.1rem;
  height: 0.48rem;
  opacity: 0;
  -webkit-transform: scale(0.3);
		  transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
		  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
		  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
		  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.chevron:before {
  left: 0;
  -webkit-transform: skewY(30deg);
		  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skewY(-30deg);
		  transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
  25% {
	opacity: 1;
  }
  33.3% {
	opacity: 1;
	-webkit-transform: translateY(2.28rem);
			transform: translateY(2.28rem);
  }
  66.6% {
	opacity: 1;
	-webkit-transform: translateY(3.12rem);
			transform: translateY(3.12rem);
  }
  100% {
	opacity: 0;
	-webkit-transform: translateY(4rem) scale(0.5);
			transform: translateY(4rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
	opacity: 1;
  }
  33.3% {
	opacity: 1;
	-webkit-transform: translateY(2.28rem);
			transform: translateY(2.28rem);
  }
  66.6% {
	opacity: 1;
	-webkit-transform: translateY(3.12rem);
			transform: translateY(3.12rem);
  }
  100% {
	opacity: 0;
	-webkit-transform: translateY(4rem) scale(0.5);
			transform: translateY(4rem) scale(0.5);
  }
}


h1, h2, h3, h4, h5, h6 {
	font-family: eurostile-extended, sans-serif;
	font-weight: 900;
	font-style: italic;
}

section {
	padding-top: 150px;
	padding-bottom: 150px;
}

section h2 {
	font-size: 1.5rem;
}

.p-feature {
	font-size: 1.2rem;
	max-width: 35em;
}

.modal {
	z-index: 9999999;
}

#choose .modal-dialog {
	max-width: 80vw;
	border: 0;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

#choose .modal-content {
	border: 0;
	border-radius: 0;
}

#choose .modal-body {
	padding: 15px;
	padding-top: 0;
	padding-bottom: 0;
}

#choose .modal-header {
	border: 0;
}

.btn-primary {
	border-radius: 25px;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	border: 0;
	background-color: #FFB924;
	color: black;
	font-weight: bold;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:focus:active, .btn-primary:hover:active, .btn-primary.active {
	background-color: #F0A300 !important;
	box-shadow: none;
	color: black !important;
}

.modal-backdrop {
	background-color: white;
	z-index: 999999;
}

.grey {
	padding-top: 75px;
	padding-bottom: 75px;
	background-color: #fafafa;
}

.blockquote {
	margin-top: 1rem;
	font-size: 1rem;
	font-style: italic;
}

.rating {
	color: #FFB924;
	margin-top: 1rem;
}

.dark-grey {
	padding-top: 75px;
	padding-bottom: 75px;
	background-color: #222120;
	color: white;
}

.footer {
	min-height: 100px;
	background: black;
	padding: 50px;
	color: white;
	font-size: 0.85rem;
}

.links-footer img {
	opacity: 0.2;
	margin-bottom: 15px;
}

.links-footer a {
	margin-right: 15px;
	color: white;
	margin-top: 10px;
	display: inline-block;
}

.languages {
	background: rgba(0, 0, 0, 0.9);
}

.languages .btn-secondary {
	background: #161616;
	border: 0;
	position: relative;
}

.languages .dropdown-menu {
	background: #161616;
	max-width: 150px;
	padding: 0;
}

.languages .dropdown-item {
	margin-top: 0;
	margin: 0;
}
.languages a {
	display: inline-block;
	color: white;
}

#section header, #product header {
	height: 450px;
	position: relative;
}

#product header {
	height: 350px;
}

#section header .text, #product header .text {
	color: white;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

#product header .text {
	text-shadow: none;
}

#section header h1, #product header h1 {
	text-transform: uppercase;
}

#section header, #product header {
	/*background: #ed9a04 url('../img/header-trial-bg.jpg') center center;*/
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center -100px;
}

#product header h2 {
	font-size: 1.1rem;
	max-width: 45em;
	margin: 0 auto;
}

.models {
	transform: translateY(-50px);
}

.model {
	background: white;
	border-radius: 4px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	display: block;
	min-height: 300px;
	padding: 15px;
	text-decoration: none;
	transition: all 150ms ease;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.model:hover {
	transform: translateY(-10px);
	text-decoration: none;
}

.mod {
	cursor: pointer;
}

.model span {
	font-family: eurostile-extended, sans-serif;
	color: black;
}


.display-text, .d3 {
	font-weight: bolder;
}

.d3 {
	font-size: 1.2rem;
	border-bottom: 1px solid #FFB924;
	max-width: 100px;
	margin: 0 auto;
	padding-bottom: 0.5rem;
	margin-bottom: 0.7em;
	color: #FFB924;
}

.foot-grey {
	background: #3A506B;
	background: #1B1B1E;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-top: 4rem;
	color: white;
}

.foot-grey h2 {
	color: #FFB924;
}

.foot-grey a {
	color: #fff;
	font-weight: bold;
	opacity: 0.9;
	line-height: 1.75em;
	transition: -webkit-all .6s cubic-bezier(.23, 1, .32, 1);
	transition: all .6s cubic-bezier(.23, 1, .32, 1);
	transition: all .6s cubic-bezier(.23, 1, .32, 1), -webkit-all .6s cubic-bezier(.23, 1, .32, 1);
}

.foot-grey a:hover {
	opacity: 1;
	color: #fff;
}

.md-stepper-horizontal {
	display:table;
	width:100%;
	margin:0 auto;
	table-layout: fixed;
	background-color:#FFFFFF;
	box-shadow: 0 5px 8px -6px rgba(0,0,0,.050);
}
.md-stepper-horizontal .md-step {
	display:table-cell;
	position:relative;
	padding:24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
	background-color:rgba(0,0,0,0.04);
}
.md-stepper-horizontal .md-step:active {
	/*border-radius: 15% / 75%;*/
}
.md-stepper-horizontal .md-step:first-child:active {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
	background-color:#757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
	display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
	width:30px;
	height:30px;
	margin:0 auto;
	background-color:#999999;
	border-radius: 50%;
	text-align: center;
	line-height:30px;
	font-size: 16px;
	font-weight: 600;
	color:#FFFFFF;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
	background-color:#00AE4D;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
	background-color:RGBA(255, 148, 0, 1.00);
}
.md-stepper-horizontal .md-step.active .md-step-circle {
	background-color: rgb(33,150,243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
	font-family:'FontAwesome';
	font-weight:100;
	content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
	display:none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
	font-family:'FontAwesome';
	font-weight:100;
	content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
	margin-top:16px;
	font-size:16px;
	font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
	text-align: center;
	color:rgba(0,0,0,.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
	font-weight: 600;
	color:rgba(0,0,0,.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
	font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-optional {
	font-size:12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
	color:rgba(0,0,0,.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
	position:absolute;
	top:36px;
	height:1px;
	border-top:1px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
	right:0;
	left:50%;
	margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
	left:0;
	right:50%;
	margin-right:20px;
}

td {
	width: 25%;
}

.table td, .table th {
	vertical-align: middle;
	border: 0;
}

.th:first-child {
	width: 15%;
}

div.input {
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 3px;
	display: inline-block;
	cursor: text;
	margin-right: 10px;
	background: white;
}

.link-normal, .link-normal:hover {
	text-decoration: none;
	color: black;
}

.sizes {
	margin-top: 1rem;
	font-size: 0.9rem;
}

.link-normal span {
	color: RGBA(7, 107, 255, 1.00);
	text-decoration: underline;
}

.table {
	border-top: 0;
}

.table thead th {
	border: 0;
}

.cart-product-title {
	font-family: Roboto, sans-serif;
	font-weight: bold;
	font-size: 1rem;
	font-style: normal;
}

.table-striped tbody tr:nth-of-type(odd) {
	background: rgba(0, 0, 0, 0.03);
}

.delete-product a {
	text-align: center;
	font-size: 0.9rem;
	line-height: 1em;
	display: inline-block;
	color: rgba(0, 0, 0, 0.7);
	transition: all 200ms ease;
}

.delete-product a:hover {
	color: red;
	text-decoration: none;
}

.delete-product .material-icons {
	vertical-align: baseline;
}

.thumb-cart {
	width: 100px;
	background: white;
}

.row-line {
	background: rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	padding-top: 20px;
	padding-bottom: 20px;

	background: #f8f9fa;
	border-bottom: 1px solid #ccd4dd;
}

.link {
	vertical-align: middle;
	display: inline-block;
	padding: 15px;
}

.link .material-icons {
	vertical-align: middle;
	position: relative;
	top: -2px;
	margin-right: 8px;
}

.btn-secondary .material-icons {
	vertical-align: middle;
	position: relative;
	top: -2px;
}

.related-product h1 {
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.related-product {
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
	padding: 2rem;
	margin-bottom: 30px;
}

.related-product div {
	position: relative;
	width: 100%;
	height: 200px;
}

.related-product img {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

.related-product img.top {
	z-index: 9;
}

.btn {
	outline: none;
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

#legal h3 {
	font-size: 1.1rem;
}

.contact {
	padding: 50px;
}

.error-input {
	border-color: red;
}

.alert {
	position: fixed;
	bottom: 40px;
	right: 20px;
	max-width: 350px;
}

.flags .dropdown-toggle::after, .select-font .dropdown-toggle::after, .select-color .dropdown-toggle::after {
	position: absolute;
	right: 15px;
	top: 15px;
}

.select-color .dropdown-toggle::after {
	width: 0;
	height: 0;
	margin: 0;
	border: 0;
}

.flags {
	text-align: left;
}

.flags .dropdown-toggle {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.flags img, .flag img {
	max-height: 15px;
	width: auto;
	margin-right: 10px;
	position: relative;
	top: -1px;
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.flags .dropdown-toggle::after {
	position: absolute;
	right: 20px;
	top: 17px;
}

.flags .dropdown-menu {
	position: fixed;
	right: 20px;
	top: 80px;
	bottom: 80px;
	width: 200px;
	border: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 350px;
	height: 350px;
	z-index: 9999999;
}

.flags .dropdown-item {
	padding: 10px;
}

.cc-revoke, .cc-window {
	z-index: 9999999999999 !important;
}

.cc-window {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.red {
	color: red;
}